$width: 3860;


.big-modal.modal-wrapper{
  width: 50vw;
  & .modal-wrapper__wrapper{
    height: 34.179275vw;

  }
  & .modal-wrapper__close{
    right: 0.5vw;
  }
}

.modal-wrapper {
  width: 2850 / 3860 * 100vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  padding: 20/ 3860 * 100vw 10/ 3860 * 100vw;
  border-radius: 5/ 3860 * 100vw;
  margin: 10/ 3860 * 100vw;
  font-family: "Akrobat", sans-serif;
  font-weight: 300;
  transition: visibility 1s;
  //filter: drop-shadow(0 20/ $width * 100vw 0 rgba(25, 43, 131, 0.50));

  &_visible {
    opacity: 1;
    visibility: visible;
    display: block;
  }

  &_hidden {
    opacity: 0;
    visibility: hidden;
    display: none;
  }

  &__wrapper {
    height: 1080 / 3860 * 100vw;
    //filter: url("#goo1");
    clip-path: polygon(0% 0%, 95% 0%, 100% 5%, 100% 100%, 10% 100%, 0% 95%, 0% 100%);
    text-align: left;
    background: rgba(255, 255, 255, 1);
    padding: 3% 4%;
    z-index: 100;
    position: relative;
    border-radius: 5/ 3860 * 100vw;
    display: flex;
    flex-wrap: wrap;
    transform: scaleX(1) scaleY(-1);
  }

  &__wrapper-inner{
    transform: scaleX(1) scaleY(-1);
  }

  &__round{
    opacity: 0.9;
    //padding-top: 0.7vw;
    filter: url("#goo");
    @media (max-width:1980px) {
      filter: url('#goo_medium');
    }
    @media (max-width:1080px) {
      filter: url('#goo_small');
    }
  }

  &__close {
    z-index: 1000;
    border: none;
    position: absolute;

    margin: 0;
    padding: 0;
    background: transparent;
    text-align: center;
    bottom: 0%;
    right: 1vw;
    cursor: pointer;

    & svg {
      width: 76/ $width * 100vw;
      min-width: 76/ $width * 100vw;
      max-width: 76/ $width * 100vw;
      height: 86/ $width * 100vw;
      min-height: 86/ $width * 100vw;
      max-height: 86/ $width * 100vw;
      & path {
        transition: 0.125s fill ease;
      }
    }

    &:hover {
      svg path:not(:last-of-type) {
        fill: #1E4382;
      }
    }
  }
}