//TODO: Поправить размеры
@use "sass:math";

.header {
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  z-index: 2;
  padding: math.div(40, 2160)  * 100% math.div(65, 2160)  * 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;

  &__wrapper {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    flex: 1 1 auto;
    text-transform: uppercase;
    text-align: center;
    font-size: 3.4vw;
    font-family: "Akrobat", sans-serif;
    font-weight: 600;

  }

  &_inner {
    color: #265EAB;
  }

  &__logo {
    position: relative;
  }

  &__logo-logo {
    width: math.div(234, 3840)  * 100vw;
    height: 264 / 3840 * 100vw;
  }

  &__btn-back {
    padding: 0;
    position: absolute;
    left: 0;
    top: 92%;
    background: none;
    border: none;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
      & svg path:not(:last-of-type), p {
        fill: #265EAB;
        color: #3377d7;
      }
    }

    & svg {
      width: 104/ 3840 * 100vw;
      height: 115/ 3840 * 100vw;
      margin-right: 15 / 3840 * 100vw;

      & path {
        transition: 0.125s fill ease;
      }
    }

    & p {
      transition: 0.125s color ease;
      width: 120/ 3840 * 100vw;
      text-transform: uppercase;
      text-align: left;
      color: #3FA8F4;
      font-family: Akrobat, serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18/ 3840 * 100vw;
    }
  }
}