$width: 3860;
.plan {

  &__titles-wp {
    text-align: center;
  }
  &-wrapper {
    display: grid;
    grid-template-columns: 1fr 1.09fr;
    gap: 80/$width * 100vw;
  }
  &__title {
    margin-bottom: 20/$width * 100vw;
    font-family: 'Akrobat';
    font-style: normal;
    font-weight: 800;
    font-size: 62/$width * 100vw;
    color: #2B40AA;
    text-transform: uppercase;

    & span {
      padding: 3/$width * 100vw 6/$width * 100vw;
      color: #FFF;
      font-size: 62/$width * 100vw;
      font-family: Akrobat, sans-serif;
      font-style: normal;
      font-weight: 800;
      text-transform: uppercase;
      background: #2B40AA;
      border-radius: 10/$width * 100vw;

      & span {
        font-weight: 400;
      }
    }
  }

  &__first {
    flex: 1 1 auto;
    height: 100%;
    display: grid;
    position: relative;
    &:after{
      margin-top: 30/$width * 100vw;
      content: "";
      right: 0;
      position: absolute;
      height: 95%;
      border-right: 1/$width * 100vw #34479E solid;
      display: block;
    }
    &-wrapper {
      padding-right: 60/$width * 100vw;
    }

    //&-wrapper {
    //  border-right: 1/$width * 100vw solid #2B40AA;
    //  padding-right: 60/$width * 100vw;
    //}
    &-title {
      color: #2B40AA;
      text-align: center;
      font-size: 62/$width * 100vw;
      font-weight: 800;
      text-transform: uppercase;
    }

    &-cont {
      &-top {
        display: flex;
        gap: 120/$width * 100vw;
        padding-bottom: 65/$width * 100vw;
        //border-bottom: 1/$width * 100vw solid #2B40AA;
      }
      &-bottom {
        &-title {
          margin-bottom: 47/$width * 100vw;
          padding: 4/$width * 100vw 14/$width * 100vw;
          font-family: 'Akrobat';
          font-style: normal;
          font-weight: 700;
          font-size: 41/$width * 100vw;
          line-height: 51/$width * 100vw;
          color: #FFFFFF;
          background: #2B40AA;

          & span {
            color: #56CCF2;
            font-weight: 700;
          }
        }
        &-wrapper {
          display: flex;
          align-items: center;
          flex-direction: column;
          border-top: 1/$width * 100vw solid #2B40AA;

          &:first-child {
            padding-bottom: 70/$width * 100vw;
          }
        }
        &-definition {
          display: flex;
          align-items: center;
          margin-bottom: 34/$width * 100vw;
          &-wrapper {
            display: flex;
            gap: 70/$width * 100vw;
          }
          &-title {
            font-family: 'Akrobat';
            font-style: normal;
            font-weight: 500;
            font-size: 45.22/$width * 100vw;
            line-height: 42/$width * 100vw;

            color: #000000;

            & span {
              color: #C71E25;
              font-weight: 800;
            }
          }
          & svg {
            margin-right: 24/$width * 100vw;
            min-width: 122/$width * 100vw;
            min-height: 87/$width * 100vw;
            max-width: 122/$width * 100vw;
            max-height: 87/$width * 100vw;
          }
          &-special {
            margin-right: 18/$width * 100vw;
            min-width: 60/$width * 100vw;
            min-height: 34/$width * 100vw;
            max-width: 60/$width * 100vw;
            max-height: 34/$width * 100vw;
          }
        }
      }
    }

    &-definition {
      display: flex;
      flex-direction: column;

      &-title {
        font-family: 'Akrobat';
        font-style: normal;
        font-weight: 700;
        font-size: 42/$width * 100vw;
        line-height: 50/$width * 100vw;
        color: #2B40AA;
        //margin-right: 24/$width * 100vw;
        //margin-top: 8/$width * 100vw;
      }

      &-description {
        font-family: 'Akrobat';
        font-style: normal;
        font-weight: 800;
        font-size: 58/$width * 100vw;
        line-height: 54/$width * 100vw;
        margin-top: 16/$width * 100vw;
        color: #C1272D;
        white-space: nowrap;

        &-text {
          font-size: 45/$width * 100vw;
        }
      }

      & svg {
        margin-right: 26/$width * 100vw;
        min-width: 54/$width * 100vw;
        min-height: 54/$width * 100vw;
        max-width: 54/$width * 100vw;
        max-height: 54/$width * 100vw;
      }
    }
  }

  &__second {
    &-title {
      margin-bottom: 27/$width * 100vw;
      color: #3FA9F5;
      font-size: 42/$width * 100vw;
      font-weight: 700;
      text-transform: uppercase;
    }
    &-cont{
      &-top{
        margin-bottom: 47/$width * 100vw;
        display: flex;
        //gap: 33/$width * 100vw;

        &-left {
          border-right: 1/$width * 100vw #2B40AA solid;
          padding-right: 60/$width * 100vw;
        }

        &-right {
          padding-left: 60/$width * 100vw;
        }

        &__title {
          margin-bottom: 31/$width * 100vw;
          display: flex;
          align-items: end;
          gap: 18/$width * 100vw;

          & svg {
            max-width: 119/$width * 100vw;
            max-height: 107/$width * 100vw;
          }

          & span {
            width: 50%;
            color: #2B40AA;
            font-size: 38/$width * 100vw;
            font-weight: 800;
            line-height: 115.789%;
            text-transform: uppercase;
          }

        }

        &-flexwp {
          display: flex;
          gap: 30/$width * 100vw;
        }

        &__block {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10/$width * 100vw;

          &-top {
            display: flex;
            gap: 10/$width * 100vw;

            & span {
              color: #1A1A1A;
              font-size: 25/$width * 100vw;
              font-weight: 500;
              text-transform: uppercase;
            }
          }

          &-number {
            color: #C1272D;
            font-size: 73.22/$width * 100vw;
            font-weight: 800;
            white-space: nowrap;

            & span {
              font-size: 45.22/$width * 100vw;
            }
          }

          & svg {
            max-height: 48/$width * 100vw;
            max-width: 60/$width * 100vw;
          }

        }
      }
      &-bottom {
        &-title {
          font-family: 'Akrobat';
          font-style: normal;
          font-weight: 700;
          font-size: 50.22/$width * 100vw;
          line-height: 60/$width * 100vw;
          margin-bottom: 25/$width * 100vw;
          color: #2B40AA;
        }
        &-tasks {

        }

        &-task {
          font-family: 'Akrobat';
          font-style: normal;
          font-weight: 500;
          font-size: 40/$width * 100vw;
          line-height: 42/$width * 100vw;
          margin-bottom: 20/$width * 100vw;
          color: #000000;
          & span {
            font-weight: 700;
            margin-right: 8/$width * 100vw;
          }
          & svg {
            margin-right: 18/$width * 100vw;
            min-width: 24/$width * 100vw;
            min-height: 24/$width * 100vw;
            max-width: 24/$width * 100vw;
            max-height: 24/$width * 100vw;
          }
          &-accent {
            color: #3FA9F5;
          }
        }
      }
    }
    &-definition {
      display: flex;
      align-items: center;
      &-title {
        font-family: 'Akrobat';
        font-style: normal;
        font-weight: 700;
        font-size: 38.22/$width * 100vw;
        line-height: 46/$width * 100vw;
        color: #C1272D;
        margin-right: 8/$width * 100vw;

        & span {
          font-weight: 500;
          font-size: 32.22/$width * 100vw;
          line-height: 39/$width * 100vw;
          color: #000000;
        }
      }
      &-description {
        font-family: 'Akrobat';
        font-style: normal;
        font-weight: 500;
        font-size: 32.22/$width * 100vw;
        line-height: 39/$width * 100vw;
        color: #000000;
      }
    }
  }
}