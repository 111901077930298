body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: linear-gradient(#2B58BC, #1E3A87);
    color: white;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div, h2, h3, h4, h5, p, section, span {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome, Edge, Opera and Firefox */
}


@font-face {
    font-family: "Akrobat";
    src: local("Akrobat"),
    url("fonts/akrobat-bold.otf");
    font-weight: 800;
}

@font-face {
    font-family: "Akrobat";
    src: local("Akrobat"),
    url("fonts/akrobat-extrabold.otf");
    font-weight: bold;
}

@font-face {
    font-family: "Akrobat";
    src: local("Akrobat"),
    url("fonts/akrobat-light.otf");
    font-weight: 300;
}

@font-face {
    font-family: "Akrobat";
    src: local("Akrobat"),
    url("fonts/akrobat-regular.otf");
    font-weight: normal;
}

@font-face {
    font-family: "Akrobat";
    src: local("Akrobat"),
    url("fonts/akrobat-semibold.otf");
    font-weight: 600;
}

/*Yandex font*/

@font-face {
    font-family: "YandexSansText-Bold";
    src: local("YandexSansText"),
    url("fonts/YandexSansText-Bold.ttf");
    font-weight: bold;
}

@font-face {
    font-family: "Yandex Sans Text";
    src: local("YandexSansText"),
    url("fonts/YandexSansText-Medium.ttf");
    font-weight: 700;
}

@font-face {
    font-family: "Yandex Sans Text";
    src: local("YandexSansText"),
    url("fonts/YandexSansText-Regular.ttf");
    font-weight: normal;
}