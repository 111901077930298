$width: 3860;

.events{
  &__h2{
    text-align: left;
    font-family: Akrobat, serif;
    font-weight : bold;
    font-size : 82/$width * 100vw;
    line-height : 70.57/$width * 100vw;
    text-transform : uppercase;
    color : #2B40AA;
    margin-bottom: 50/$width * 100vw;
  }
  &__wrapper{
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 230/$width * 100vw;
    row-gap: 40/$width * 100vw;
    text-align: left;
  }
  &__h3{
    font-family: Akrobat, serif;
    font-weight : bold;
    font-size : 56/$width * 100vw;
    line-height : 66.5/$width * 100vw;
    text-transform : uppercase;
    color : #70767C;
  }


  &__item{
    display: flex;
    font-family: Akrobat, serif;
    font-weight : bold;
    font-size : 42/$width * 100vw;
    line-height : 50.88/$width * 100vw;
    color: black;
    & svg{
      margin-right: 38/$width * 100vw;
      min-width: 87/$width * 100vw;
      min-height: 87/$width * 100vw;
      max-width: 87/$width * 100vw;
      max-height: 87/$width * 100vw;
    }
  }

}