@use "sass:math";

.button-round{
  filter: url('#goo');
  @media (max-width:1980px) {
    filter: url('#goo_medium');
  }
  @media (max-width:1080px) {
    filter: url('#goo_small');
  }
}

.button{
  &__content {
  }
  background: #46abf3;
  color: #ffffff;
  border: none;
  clip-path: polygon(5% 0%, 95% 0%, 100% 50%, 95% 100%, 5% 100%, 0% 50% );
  cursor: pointer;
  padding: math.div(20, 3840)  * 100vw math.div(100, 3840)  * 100vw;
  font-family: Akrobat, sans-serif;
  font-weight : 600;
  font-size : 3.5rem;
  &:hover{
    color: #2B40AA;
    background: white;
  }
}
