// TODO: РАЗМЕР
$width: 120 / 3860 * 100%;
$widthAll: 3860;
$height: 650 / 2160 * 100%;
h1,h3, h2, p{
  margin: 0;
}


.alphabet {
  &_inner{
    background: #3FA9F5;
  }
  width: 3.6088082902%;
  //height: $height;
  height: 600/$widthAll * 100vw;
  position: absolute;
  z-index: 3;
  left: -0.5vw;
  top: 0;
  bottom: 0;
  margin-top: 27vh;
  padding: 5/ $widthAll * 100vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: background-color 0.25s, opacity 0.25s;
  clip-path: polygon(0% 0%, 100% 15%, 100% 85%, 0% 100%);
  background: #345AC1;
  &:hover{
    cursor: pointer;
    background: #0f359c;
    //background: white;
    //color: #2B40AA;
  }

  &__wrapper{
    clip-path: polygon(92% 0, 100% 8%, 100% 92%, 92% 100%, 0 100%, 0 0);
    text-align: left;
    padding: 3% 4% 5% 4.5%;
    background: #3FA9F5;
  }

  &__close {
    background: transparent;
    position: absolute;
    text-align: center;
    border: none;
    color: red;
    font-size: 25 / $widthAll * 100vw;
    bottom: 0%;
    right: 1.2vw;
    cursor: pointer;

    & svg {
      width: 76/ $widthAll * 100vw;
      min-width: 76/ $widthAll * 100vw;
      max-width: 76/ $widthAll * 100vw;
      height: 86/ $widthAll * 100vw;
      min-height: 86/ $widthAll * 100vw;
      max-height: 86/ $widthAll * 100vw;
      & path {
        transition: 0.125s fill ease;
      }
    }

    &:hover {
      svg path:not(:last-of-type) {
        fill: #1E4382;
      }
    }
  }

  &__all-regions {
    max-width: 70/ $widthAll * 100vw;
    margin: 0 auto;
    font-size: 0.5vw;
    font-family: Akrobat, sans-serif;
    font-weight: 300;
    text-align: center;
  }

  &__letters {
    font-size: 1.2vw;
    font-family: "Yandex Sans Text", sans-serif;
    font-weight: 600;
    & div{
      text-align: center;
    }
  }
  &_closed{
    opacity: 0;
    visibility: hidden;
  }
}


.alphabet-open{
  transition: all 1s;
  width: 2240/ $widthAll * 100vw;
  max-height: 1300/ $widthAll * 100vw;
  z-index: 3;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  font-family: "Akrobat", serif;
  text-align: left;
  top: 20%;
  left: -0.5%;
  filter: drop-shadow(-20/ $widthAll * 100vw 40/ $widthAll * 100vw 0 rgba(25, 43, 131, 0.5)) ;

  &_open{
    visibility: visible;
    opacity: 0.92;
    //background : #3FA9F5;
  }
  &__h2{
    text-transform: uppercase;
    font-size : 40/ $widthAll * 100vw;
    margin-bottom: 50/ $widthAll * 100vw;
  }
  &__h3{
    text-transform: uppercase;
    font-family: YandexSansText-Bold, serif;
    font-size : 51/ $widthAll * 100vw;
    line-height : 59.91/ $widthAll * 100vw;
    color : #2B40AA;
    margin-bottom: 15/ $widthAll * 100vw;
  }

  &__rounded{
    filter:url("#goo");
    @media (max-width:1980px) {
      filter: url('#goo_medium');
    }
    @media (max-width:1080px) {
      filter: url('#goo_small');
    }
  }
  &__items{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 1000/ $widthAll * 100vw;
  }
  &__item{
    padding-right: 10/ $widthAll * 100vw;
    & p {
      font-size : 23/ $widthAll * 100vw;
      font-weight: normal;
      margin-bottom: 15/ $widthAll * 100vw;
      &:hover{
        color: #2B40AA;
        cursor: pointer;
      }
    }
    & :not(:last-child){
      margin-right: 20/ $widthAll * 100vw;
    }
  }
}