$width: 3860;

.opornonet-def {
  font-family: "Akrobat", sans-serif;
  font-weight: 300;
  text-align: left;
  font-size: 52/$width * 100vw;
  line-height: 58/$width * 100vw;
  color: #58595B;
  gap: 100/$width * 100vw;


  display: grid;
  grid-template-columns: 1fr 1fr;

  &__h2, &__criteria-h2, &__h3 {
    font-size: 52/$width * 100vw;
    font-weight: bold;
    text-transform: uppercase;
    color: #2B40AA;
  }

  &__criteria-content{
    display: grid;
    row-gap: 70/$width * 100vw;
  }

  &__criteria-h2 {
    color: black;
    margin: 27/$width * 100vw 0;
  }

  &__h3{
    align-self: flex-end;
  }

  &__item{
    margin-bottom: 39/$width * 100vw;
    &-title{
      margin-bottom: 10px;
      display: flex;
    }
    &-text {
      color: #58595B;
      font-size: 40/$width * 100vw;
      line-height: 110%;
    }
    & svg{
      height: 87/$width * 100vw;
      max-width: 96/$width * 100vw;
      margin-right: 20/$width * 100vw;
    }

  }

  &__bold {
    color: #58595B;
    font-size: 46/$width * 100vw;
    font-weight: 700;
    line-height: 108.696%;
  }

  &__p:not(:last-of-type) {
    margin-bottom: 80/$width * 100vw;
  }
  & span{
    font-weight: bold;
  }
}

ul {
  margin: 0;
  padding-inline-start: 20px;
}
li {
  color: #58595B;
  font-size: 44/$width * 100vw;
  line-height: 108.696%;
}
li::marker {
  color: #2B40AA;
}

.main__item {
  padding: 55/$width * 100vw 60/$width * 100vw 74/$width * 100vw;
  border: 3/$width * 100vw solid #3FA9F5;
  border-radius: 15/$width * 100vw;
  //clip-path: polygon(0% 0%, 100% 0%, 100% 5%, 100% 100%, 10% 100%, 0% 95%, 0% 100%);

  //&::before {
  //  content: "";
  //  display: block;
  //  position: relative;
  //  top: 5px;
  //  left: 5px;
  //  height: inherit;
  //  width: inherit;
  //  background-color: red;
  //  clip-path: inherit;
  //}
}
.main__title {
  margin-bottom: 38/$width * 100vw;
  & svg {
    height: 155/$width * 100vw;
    max-width: 140/$width * 100vw;
    margin-right: 38/$width * 100vw;
  }
}
.main__h2 {
  font-size: 78/$width * 100vw;
  font-weight: 800;
  line-height: 105.128%;
  color: #3FA8F4;
}
.main__p {
  color: #58595B;
  font-size: 56/$width * 100vw;
  font-weight: 700;
  line-height: 110%;
}