$width: 3840;

.st0 {
  opacity: 0.18;
}

.st1 {
  fill: #FFFFFF;
}

.st2 {
  opacity: 0.13;
}

.map {
  height: 100vh;
  width: 100vw;
  margin: auto;
  overflow: scroll;

  &__video {
    position: absolute;
    top:0;

    z-index: -2;
    & video{
      width: 100vw;
    }
  }

  &::-webkit-scrollbar { /* chrome based */
    width: 0; /* ширина scrollbar'a */
    background: transparent; /* опционально */
  }

  &__map-wrapper {
    //display: inline-block;
    padding-left: 2vw;
    padding-right: 2vw;
  }

  &__roads {
    position: absolute;
    pointer-events: none;
    width: 90.1vw;

    left: 2.083333vw;
    top: 19.2vw;
    height: 29.5vw;

    //left: 2.183333vw;
    //top: 35.68%;
    //height: 55.2vh;
  }

  &__gerb {
    filter: drop-shadow(3/ $width * 100vw 3/ $width * 100vw 2/ $width * 100vw rgba(0, 0, 0, .7));
    transition: opacity 1s;
    position: absolute;
    z-index: 0;
    visibility: hidden;
    opacity: 0;
    display: grid;
    grid-template-columns: auto auto;
    gap: 16/ $width * 100vw;
    align-items: center;

    &_open {
      opacity: 1;
      visibility: visible;
    }
  }

  &__gerb-img {
    width: 90 / $width * 100vw;
    height: 90/ $width * 100vw;
  }

  &__gerb-p {
    text-align: left;
    text-transform: uppercase;
    font-family: Akrobat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32/ $width * 100vw;
    width: 258 / $width * 100vw;
  }

  &__svg-map {
    margin-left: -2.5vw;
    width: 102.5%;

    margin-top: -4vw;
    height: 52.5vw;

    //margin-top: -9vh;
    //height: 100vh;
    & g g[data-id]:hover {
      opacity: 0.9;

      & path {
        fill: #0387f3;
      }
    }
  }

  &__g {
    transition: 1s opacity ease;

    & path {
      transition: 1s fill ease;
    }
  }

  &__path_active {
    opacity: 0.9 !important;

    & path {
      fill: #0387f3;
    }
  }
}

.info-map {
  pointer-events: none;
  transition: all 0.5s;
  visibility: hidden;
  opacity: 0;
  top: 28%;
  left: 48%;
  position: absolute;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 40 / $width * 100vw;
  row-gap: 20 / $width * 100vw;
  z-index: 2;

  text-shadow: 4/ $width * 100vw 4/ $width * 100vw 0/ $width * 100vw #1D3791;

  &_open {
    visibility: visible;
    opacity: 1;
  }

  &__button {
    margin-top: 40/ $width * 100vw;
    filter: drop-shadow(3/ $width * 100vw 3/ $width * 100vw 2/ $width * 100vw rgba(0, 0, 0, .7));

    & button {
      clip-path: polygon(10% 0%, 90% 0%, 100% 50%, 90% 100%, 10% 100%, 0% 50%);

      pointer-events: all;
      font-weight: 600;
      text-transform: uppercase;
      min-width: 373/ $width * 100vw;
      font-size: 42/ $width * 100vw;
    }
  }

  &__thousands {
    margin-right: 0.2em;
  }

  &__other {
    margin-right: 0.1em;
  }

  & div {
    font-family: Akrobat, sans-serif;
    font-weight: bold;
    text-transform: uppercase;

    &:first-child {
      font-size: 66/ $width * 100vw;
      line-height: 78.57/ $width * 100vw;
      color: #3FA9F5;
      text-align: right;

    }

    &:nth-child(2) {
      font-size: 109/ $width * 100vw;
      line-height: 130/ $width * 100vw;
      color: #FFFFFF;
      text-transform: lowercase;
      position: relative;

      //& custom {
      //  position: relative;
      //
      //  //&:before {
      //  //  filter: drop-shadow( 3/ $width * 100vw 3/ $width * 100vw 2/ $width * 100vw rgba(0, 0, 0, .7));
      //  //  content: "";
      //  //  width: 92/ $width * 100vw;
      //  //  height: 92/ $width * 100vw;
      //  //  display: block;
      //  //  position: absolute;
      //  //  top: -50%;
      //  //  right: 15%;
      //  //  background: url("../../../assets/road/roads.svg") no-repeat center center;
      //  //  background-size: 100% 100%;
      //  //}
      //}

      & span {
        font-size: 2em;
      }
    }

    &:nth-child(3) {
      text-align: right;
      font-size: 42/ $width * 100vw;
      line-height: 50.88/ $width * 100vw;
      color: #FFFFFF;
    }

    &:nth-child(5) {
      &:after {
        filter: drop-shadow(3/ $width * 100vw 3/ $width * 100vw 2/ $width * 100vw rgba(0, 0, 0, .7));
        content: "";
        width: 84/ $width * 100vw;
        height: 84/ $width * 100vw;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        background: url("../../../assets/road/emblem.svg") no-repeat center center;
        background-size: 100% 100%;

      }
    }

    &:nth-child(7) {
      &:after {
        filter: drop-shadow(3/ $width * 100vw 3/ $width * 100vw 2/ $width * 100vw rgba(0, 0, 0, .7));
        content: "";
        width: 68/ $width * 100vw;
        height: 90/ $width * 100vw;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        background: url("../../../assets/road/emblem-shield.svg") no-repeat center center;
        background-size: 100% 100%;
      }
    }

    &:nth-child(5), &:nth-child(7) {
      position: relative;
      text-align: right;
      padding-right: 100/ $width * 100vw;
      font-size: 42/ $width * 100vw;
      line-height: 50.88/ $width * 100vw;
      color: #3FA9F5;
    }

    &:nth-child(6), &:nth-child(8) {
      text-align: left;
      font-size: 49/ $width * 100vw;
      line-height: 58.88/ $width * 100vw;

      & span {
        font-size: 2em;
      }
    }
  }
}
