.main {
  height: 100vh;
  width: 100vw;

  &__region{
    padding: 15/ 3840  * 100vw 145/ 3840  * 100vw 15/ 3840  * 100vw 60/ 3840  * 100vw;
    top: 320/ 3840  * 100vw;
    right: 0;
    position: absolute;

    clip-path: polygon(5% 0%, 100% 0%, 100% 50%, 100% 100%, 5% 100%, 0% 50%);
    background: #1D5DA9;
    display: flex;
    align-items: center;

    font-family: Akrobat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 68/ 3840  * 100vw;

    & svg{
      width: 42/ 3840  * 100vw;
      height: 56/ 3840  * 100vw;
      margin-right: 20/ 3840  * 100vw;
    }
  }

  &__panel {
    width: 100%;
    bottom: 2.5%;
    z-index: 2;
    position: absolute;
    display: flex;
    justify-content: space-evenly;
    align-items: start;
  }

  &__scale {
    margin-top: -45/ 3840  * 100vw;
    font-family: "Akrobat", sans-serif;
    font-size: 36/ 3840  * 100vw;
    text-transform: uppercase;
    color: #1E4382;
    font-weight: bold;
    text-align: center;

    & button {
      padding: 0;
      cursor: pointer;
      background: transparent;
      border: none;
    }

    & path {
      transition: 0.125s fill ease;
    }

    & svg {
      width: 128/ 3840  * 100vw;
      height: 141/ 3840  * 100vw;

      &:hover path:first-child {
        fill: #1E4382;
      }
    }
  }

  &__scale-btns {
    display: grid;
    grid-auto-columns: auto;
    grid-auto-flow: column;
  }

  &__btn {
    width: auto;

    & button {
      min-width: auto;
      font-size: 55 / 3840  * 100vw;
      //width: auto;
      text-transform: uppercase;
      //TODO: УБРАТЬ ПОТОМ
      width: 740 / 3840  * 100vw;
    }
  }

  &__svg {
    width: 579 / 3840  * 100vw;
    height: 164 / 3840  * 100vw;

  }

  &__control-btns {
    display: table;
    position: absolute;
    z-index: 3;
    left: 0;
    top: 64.5vh;
    bottom: 0;
    padding-left: 85/ 3840  * 100vw;
  }

  &__sound-btn{
    background: none;
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
    font-family: "Akrobat", sans-serif;
    font-size: 0.9375vw;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    text-align: center;
    & p {
      margin-top: -0.2vw;
    }
    & svg{
      width: 2.1502590674vw;
      height: 2.3834196891vw;
    }
    &:hover {
      cursor: pointer;
      svg path {
        &:not(:first-child) {
          fill: #2B40AA;
          stroke: #2B40AA;
        }

        fill: white;
      }
    }
  }
}

.control-btns {
  &__wrapper {
    display: grid;
    gap: 22/ 3840  * 100vw;
  }

  &__item {
    background: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 36/ 3840  * 100vw;
    text-align: left;
    justify-content: left;
    font-family: "Akrobat", sans-serif;
    font-weight: bold;
    color: #1E4382;;
    cursor: pointer;

    & button {
      padding: 0;
    }

    & svg {
      width: 128/ 3840  * 100vw;
      height: 141/ 3840  * 100vw;
      margin-bottom: 10/ 3840  * 100vw;
    }

    & path, p {
      transition: 0.125s fill ease, 0.125s color ease;
    }

    &:hover {
      & svg > path:first-child {
        fill: #1E4382;
      }
    }
  }


}

