$width: 3860;

.routes {
  width: 100%;
  position: relative;

  &__h2 {
    text-align: left;
    font-family: Akrobat, serif;
    font-weight: bold;
    font-size: 64/$width * 100vw;
    max-width: 90%;
    line-height: 70.57/$width * 100vw;
    height: 4vw;
    text-transform: uppercase;
    color: #2B40AA;
    margin-bottom: 50/$width * 100vw;
  }

  &__img-wrapper {
    width: 100%;
    height: 24.466321vw;
    margin-bottom: 52/$width * 100vw;
    clip-path: polygon(0% 0%, 95% 0%, 100% 5%, 100% 100%, 10% 100%, 0% 95%, 0% 100%);
    overflow: hidden;
  }

  &__imgs-wrapper {
    display: flex;
    transition: all 1s ease;
  }

  &__img {
    width: 100%;
    height: 25.4663212435vw;
    margin-bottom: 52/$width * 100vw;
    flex: 0 0 auto;
  }

  &__control-btns {
    display: flex;
    justify-content: space-between;
  }

  &__flipping {
    display: flex;
    font-weight: 800;
    font-size: 36.5/$width * 100vw;
  }

  &__flipping-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:first-child {
      margin-right: 52/$width * 100vw;
    }

    & svg {
      margin-bottom: 7/$width * 100vw;
    }

    &_hidden {
      visibility: hidden;
    }
  }

  &__media {
    font-weight: 600;
    font-size: 36/$width * 100vw;
    display: flex;
    align-items: center;
  }

  &__play {
    display: flex;
    align-items: center;
  }

  &__play-btn {
    margin-right: 24/$width * 100vw;
  }

  &__label {
    color: #2B40AA;
  }

  &__right {
    transform: rotate(180deg);
  }

  &__btn:active {
    color: white;
    cursor: pointer;

    & svg > path {
      fill: #1E4382;
      stroke: #1E4382;

      &:first-child {
        fill: #e5e3e3;
      }
    }
  }

  &__alice {
    margin-right: 53/$width * 100vw;
    margin-top: -40/$width * 100vw;
    width: 93/$width * 100vw * 1.5 !important;
    height: 93/$width * 100vw * 1.5 !important;

    & .alice__play{
      transition: all 1s ease;
      visibility: hidden;
      opacity: 0;
    }
    &:active {
      cursor: pointer;

      & path:first-child {
        fill: #e5e3e3;
      }
    }
    &_animate{
      animation: pulse .8s alternate linear infinite;
      & .alice__play{
        visibility: visible;
        opacity: 1;
      }
    }

  }

  &__round {
    opacity: 1;
    filter: url("#goo");
    @media (max-width: 1980px) {
      filter: url('#goo_medium');
    }
    @media (max-width: 1080px) {
      filter: url('#goo_small');
    }
  }

  & svg {
    width: 93/$width * 100vw;
    height: 93/$width * 100vw;
  }
}

.routes-video {
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 80%;
  height: 100%;

  &__wrapper {
    position: relative;
  }

  &__close {
    z-index: 2;
    background: transparent;
    position: absolute;
    right: 0;
    padding: 5/$width * 100vw 20/$width * 100vw 5/$width * 100vw 5/$width * 100vw;
    font-size: 50/$width * 100vw;

    &:hover {
      cursor: pointer;
      color: black;
    }
  }

  & video {
    width: 100%;
    height: 100%;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  50% {
  }
  100% {
    transform: scale(1.1);
  }
}