$width: 3860;

.key-figures {

  font-family: Akrobat, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: #2B40AA;
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr 1fr;
  gap: 75/$width * 100vw;


  &__flex {
    display: flex;
    justify-content: flex-end;
    & svg {
      height: 90/$width * 100vw;
      width: 87/$width * 100vw;
    }
  }

  &__h3 {
    color: #2B40AA;
    font-size: 66/$width * 100vw;
    line-height: 78.57/$width * 100vw;
    margin: 0 0 60/$width * 100vw;
  }

  &__first {
    flex: 1 1 auto;
    height: 100%;
    display: grid;
    align-items: center;
    position: relative;
    &:after{
      content: "";
      right: 0;
      position: absolute;
      height: 100%;
      border-right: 1/$width * 100vw #34479E solid;
      display: block;
    }
  }
  &__first-wrapper{
    align-content: start;
    display: grid;
    align-items: center;
    grid-template-columns: 7fr 9fr;
    column-gap: 40/$width * 100vw;
    row-gap: 20/$width * 100vw;
    text-align: right;
    padding-right: 75/$width * 100vw;

    & > div:nth-of-type(2n) {
      color: #C1272D;
      text-align: left;
      font-size: 49/$width * 100vw;

      & span {
        font-size: 2.5em;
      }
    }
    & > div:nth-of-type(2n - 1) {
      font-size: 42/$width * 100vw;

      & svg {
        margin-left: 30/$width * 100vw;
      }

      & svg path {
        fill: #2B40AA;
      }
    }

    & > div:nth-of-type(1) {
      font-size: 66/$width * 100vw;
    }

    & > div:nth-of-type(2) {
      font-size: 81/$width * 100vw;
    }

    & > div:nth-of-type(3) {
      color: #3FA9F5;
    }
  }

  &__second {
    flex: 1 1 auto;
    text-align: left;
  }

  &__item {
    display: flex;
    position: relative;
    padding-bottom: 25/$width * 100vw;
    margin-bottom: 25/$width * 100vw;
    &:not(:last-of-type):after{
      content: "";
      bottom: 0;
      position: absolute;
      width: 100%;
      border-bottom: 1/$width * 100vw #34479E solid;

      display: block;
    }
  }

  &__year {
    align-self: flex-start;
    margin-right: 10/$width * 100vw;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    color: #2B40AA;
    font-size: 66/$width * 100vw;
    line-height: 78.57/$width * 100vw;

    & svg {
      width: 80/$width * 100vw;
      margin-right: 30/$width * 100vw;
    }
  }

  &__numbers {
    flex: 1 1 auto;
    display: grid;
    gap: 20/$width * 100vw;
    color: black;
    font-size: 29/$width * 100vw;
    font-weight: normal;
    text-transform: none;
    & span {
      font-weight: bold;
      font-size: 1.5em;
      height: 80/$width * 100vw;
      color: #C1272D;
    }

    & svg{
      flex: 0 0 auto;
      width: 2em;
      height: 2em;
      margin-right: 0.5em;
    }
  }

  &__number{
    max-width: 650/$width * 100vw;
    display: flex;
    align-items: center;
  }

  &__title-bottom {
    grid-column: 1/3;
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr;
    justify-content: center;
    align-items: center;
    color: #3FA8F4;
    text-align: center;
    font-size: 1.0880829016vw;
    font-weight: 700;

    &_divider {
      border-bottom: 1px solid #2B40AA;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: 1/3;
    text-align: left;

    & svg {
      width: 97/$width * 100vw;
      height: 81/$width * 100vw;
      flex-shrink: 0;
    }
  }

  &__num {
    display: flex;
    align-items: center;
    margin: 0 31/$width * 100vw 0 16/$width * 100vw;
    color: #C1272D;
    font-size: 117/$width * 100vw;
    font-weight: 800;

    & span {
      color: #C1272D;
      line-height: normal;
      font-size: 90/$width * 100vw !important;
      font-weight: 700;
    }

    &-text {
      color: #2B40AA;
      font-size: 42/$width * 100vw;
      font-weight: 700;
    }
  }

  &__p {
    color: #1A1A1A;
    font-size: 29/$width * 100vw;
    font-weight: 500;
  }
}